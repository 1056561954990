@import "npm:@fortawesome/fontawesome-free/css/fontawesome.min.css";
@import "npm:@fortawesome/fontawesome-free/css/brands.min.css";
@import "npm:@fortawesome/fontawesome-free/css/solid.min.css";
@import "npm:bootstrap/dist/css/bootstrap.min.css";
@import "npm:animate.css/animate.min.css";
@import "npm:slick-carousel/slick/slick.css";
@import "npm:owl.carousel/dist/assets/owl.carousel.min.css";
@import "npm:owl.carousel/dist/assets/owl.theme.default.min.css";
/*@import "../assets/css/owl.carousel.min.css";*/
/*@import "../assets/css/owl.theme.default.min.css";*/
/*@import "../assets/css/custom.css";*/